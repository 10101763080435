@font-face {
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 300;
    src: local('TT Norms'),
    url('./fonts/TTNorms-Regular.woff2') format('woff2'),
    url('./fonts/TTNorms-Regular.woff') format('woff');

}

@font-face {
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 400;
    src: local('TT Norms'),
    url('./fonts/TTNorms-Regular.woff2') format('woff2'),
    url('./fonts/TTNorms-Regular.woff') format('woff');

}

@font-face {
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 500;
    src: local('TT Norms Bold'),
    url('./fonts/TTNorms-Bold.woff2') format('woff2'),
    url('./fonts/TTNorms-Bold.woff') format('woff');
}

@font-face {
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 600;
    src: local('TT Norms Bold'),
    url('./fonts/TTNorms-Bold.woff2') format('woff2'),
    url('./fonts/TTNorms-Bold.woff') format('woff');
}